import React from 'react';
import {connect} from 'react-redux';
import cn from 'classnames';
import withLocate from '~/hocs/withLocate';
import {clearBrand, getModelsRequest, setBrand} from '$/redux/searchFields/actions';
import SelectWithInput from '~/components/SelectWithInput/SelectWithInput';
import './InputBrand.scss';

let timeout;

class InputBrand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            value: props.brand.text || null,
            dataSource: [],
            data: []
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const {brand: prevBrand} = prevProps;
        const {brand} = this.props;

        if (prevBrand.slug !== brand.slug) {
            this.setState({value: brand.text})
        }
    }

    getData = (value) => {
        const {language} = this.props;

        this.setState({loading: true});

        const additionalCallback = (res) => {
            this.setState({
                data: res.data
            }, this.filterOptions)
        };

        getModelsRequest(value, language, additionalCallback);
    };

    filterOptions = () => {
        const {value, data} = this.state;
        const filtederData = value ? data : data.filter(i => i.type < 3);

        this.createOptions(filtederData);
    };

    createOptions = (data) => {
        const {t} = this.props;
        const groups = [
            {
                options: [],
                type: 1,
                label: <span>{t('home: promo: fields: brands: my')}</span>
            },
            {
                options: [],
                type: 2,
                label: <span>{t('home: promo: fields: brands: all')}</span>
            },
            {
                options: [],
                type: 3,
                label: <span>{t('home: promo: fields: brands: models')}</span>
            }
        ];

        data.forEach(({id, label: text, slug, type: itemType, brand_model_id}) => {
            groups.forEach(group => {
                group.options = group.type === itemType
                    ? group.options.concat({
                        id,
                        value: `${id}--${text}`,
                        text,
                        slug,
                        brand_model_id
                    })
                    : group.options;
            })
        });

        this.setState({
            loading: false,
            dataSource: groups
        });
    };

    handleSearch = value => {
        this.setState({value});

        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(this.getData(value), 500);
    };

    handleSelect = data => {
        const {setBrand} = this.props;

        setBrand(data);
        this.setState({
            value: data.text,
            dataSource: []
        });
    };

    handleBlur = () => {
        const {brand, clearBrand} = this.props;
        const {value} = this.state;
        if (!value) {
            clearBrand();
        }
        if (value && value.trim() !== brand.text) {
            this.setState({value: ''});
            clearBrand();
        }
    };

    render() {
        const {t, brand} = this.props;
        const {loading, value, dataSource} = this.state;

        return (
            <div
                className={cn('InputBrand', {
                    'input-with-value': value
                })}
            >
                <SelectWithInput
                    value={value}
                    placeholder={t('home: promo: fields: brand')}
                    options={dataSource}
                    loading={loading}
                    onFocus={this.getData}
                    onBlur={this.handleBlur}
                    onSearch={this.handleSearch}
                    onSelect={this.handleSelect}
                    initialData={brand}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    language: state.locate.language,
    translations: state.locate.translations,
    brand: state.searchFields.selected.brand
});

const dispatchedActions = {
    setBrand,
    clearBrand
};

export default connect(
    mapStateToProps,
    dispatchedActions
)(withLocate(InputBrand));
