import './InputSearchPlace.scss';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslations} from '~/hook/Translations.hook';
import {useLocale} from '~/hook/Locale.hook';
import {classes} from '~/helpers/classes';
import isEmpty from 'lodash/isEmpty';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import {compose, withProps} from 'recompose';
import Input from 'antd/lib/input/Input';
import {setPlace} from '$/redux/searchFields/actions';
import Icon from '~/components/Icon/Icon';
import Spinner from '~/components/Spinner/Spinner';
import {getPlace} from '$/helpers/getPlace';
import {getParamByPrefix, parseGeotypes} from '$/helpers/url/parsers';
import {CITY, GEO_TYPE, LATITUDE, LONGITUDE} from '$/constants/searchParameters';

export const InputSearchPlace = compose(
    withProps({
        loadingElement: <Spinner color="rose"/>,
    }),
    withScriptjs)(({bounds}) => {

    const dispatch = useDispatch();
    const t = useTranslations();
    const searchBoxRef = React.useRef();
    const {
        place,
        userCurrentPosition,
        params,
        carPosition,
        browserPosition
    } = useSelector(state => ({
        place: state.searchFields.selected.place,
        userCurrentPosition: state.common.userCurrentPosition,
        params: state.search.parsedUrlParams,
        carPosition: state.search.carPosition,
        browserPosition: state.search.browserPosition,
    }));
    const {language} = useLocale();
    const [searchBoxValue, setSearchBoxValue] = React.useState('');

    React.useEffect(() => {
        const setCarPosition = async () => {
            const place = await getPlace({
                lat: carPosition.lat,
                lng: carPosition.lng,
                language
            });
            dispatch(setPlace(place));
        };
        if (carPosition) {
            setCarPosition();
        }
    }, [carPosition]);

    React.useEffect(() => {
        const [lat, lng, city] = [LATITUDE, LONGITUDE, CITY, GEO_TYPE].map(prefix => getParamByPrefix(params, prefix));
        const setBrowserPosition = async () => {
            const place = await getPlace({...browserPosition, language});
            dispatch(setPlace(place));
        };
        if (!lat && !lng && !city && browserPosition) {
            setBrowserPosition();
        }
    }, []);

    React.useEffect(() => {
        if (place?.text) {
            setSearchBoxValue(place?.text)
        }
    }, [place]);

    React.useEffect(() => {
        if (!searchBoxValue) {
            dispatch(setPlace({}));
        }
    }, [searchBoxValue]);

    const setCurrentUserPlace = () => {
        if (!isEmpty(userCurrentPosition)) {
            dispatch(setPlace(userCurrentPosition));
        }
    };

    const onPlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        dispatch(setPlace({
            text: places[0].formatted_address,
            type: parseGeotypes(places[0].types),
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng(),
            address_components: places[0].address_components
        }));
    };

    const inputClassNames = classes(
        'input-search-place__input',
        ['input-search-place__input_geolocation-disable', !isEmpty(userCurrentPosition)]
    );

    return (
        <div className="input-search-place">
            <StandaloneSearchBox
                ref={searchBoxRef}
                bounds={bounds}
                onPlacesChanged={onPlacesChanged}
            >
                <>
                    <Input
                        value={searchBoxValue}
                        onChange={e => setSearchBoxValue(e.target.value)}
                        placeholder={t('home: promo: fields: location')}
                        className={inputClassNames}
                    />
                    {!isEmpty(userCurrentPosition) && (
                        <div
                            className="input-search-place__set-location"
                            onClick={setCurrentUserPlace}
                        >
                            <Icon type="current-location"/>
                        </div>
                    )}
                </>
            </StandaloneSearchBox>
        </div>
    );
});
