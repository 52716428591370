import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import TimePicker from 'antd/lib/time-picker';
import cn from 'classnames';
import withLocate from '~/hocs/withLocate';
import Icon from '~/components/Icon/Icon';
import {setTime} from '$/redux/searchFields/actions';

import './InputTime.scss';

const format = 'HH:mm';

class InputTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultOpenValue: moment(props.time || '00:00', format)
        };
    }

    onChange = (date, dateString) => {
        const {setTime} = this.props;

        setTime(dateString);
    };

    onOpenChange = () => {
        const {time} = this.props;
        const currentDay = new Date();
        const currentHours = currentDay.getHours();
        const currentMinutes = currentDay.getMinutes();

        this.setState({
            defaultOpenValue: moment(
                time || currentMinutes < 30 ? `${currentHours}:30` : `${currentHours + 1}:00`,
                format
            )
        });
    };

    render() {
        const {t, time, constants} = this.props;
        const {defaultOpenValue} = this.state;
        const minuteStep = constants ? constants.time_step / 60 : 1;

        return (
            <div className="InputTime">
                <TimePicker
                    format={format}
                    value={time && moment(time, format)}
                    defaultOpenValue={defaultOpenValue}
                    placeholder={t('home: promo: fields: datetime')}
                    suffixIcon={<Icon type="calendar"/>}
                    popupClassName={cn('InputTime-dropdown', {
                        modified: minuteStep >= 10
                    })}
                    onChange={this.onChange}
                    onOpenChange={this.onOpenChange}
                    minuteStep={minuteStep}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    time: state.searchFields.selected.time,
    constants: state.countryInfo.info.settings_constants
});

const dispatchedActions = {
    setTime
};

export default connect(
    mapStateToProps,
    dispatchedActions
)(withLocate(InputTime));
