import Geocode from 'react-geocode';
import get from 'lodash/get';
import { isIncludedType, parseGeotypes } from './url/parsers';
import { STATE_AREA, STATE_AREA_BY_GEOCODE } from '../constants/geotypes';

const API_KEY_GOOGLE_MAPS = process.env.NEXT_PUBLIC_GEOCODING_KEY;

Geocode.setApiKey(API_KEY_GOOGLE_MAPS);

const isUnnamedAddress = (address) =>
  address && !address.toLowerCase().indexOf('unnamed');

const parseResults = (results = [], geotype) => {
  const gt = geotype === STATE_AREA ? STATE_AREA_BY_GEOCODE : geotype;

  const address = results.find(({ formatted_address = '', types = [] }) =>
    geotype ? isIncludedType(gt, types) : !isUnnamedAddress(formatted_address)
  );

  if (address) {
    const {
      geometry: {
        viewport: { northeast, southwest },
        location: { lat, lng },
      },
    } = address;

    return {
      text: get(address, 'formatted_address', ''),
      type: parseGeotypes(get(address, 'types', [])),
      lat,
      lng,
      bounds: [
        [northeast.lat, northeast.lng],
        [southwest.lat, southwest.lng],
      ],
      address_components: results,
    };
  }

  return {
    text: '',
  };
};

export const getPlace = async ({ lat, lng, language, geotype = '' }) => {
  if (language) {
    Geocode.setLanguage(language);
  }
  try {
    const res = await Geocode.fromLatLng(lat, lng);
    return parseResults(res.results, geotype);
  } catch (error) {
    console.error(error);
  }
};
