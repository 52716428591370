import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import './Dropdown.scss';
import Option from './Option/Option';
import OptGroup from './OptGroup/OptGroup';
import Spinner from '../Spinner/Spinner';
import Portal from '../Portal/Portal';
import offset from '$/helpers/offset';

const {array, bool, func, string} = PropTypes;

class Dropdown extends PureComponent {
    static propTypes = {
        options: array,
        onChange: func,
        notFoundContent: string,
        loading: bool,
        onFakeClick: func
    };

    static defaultProps = {
        options: [],
        onChange: () => null,
        onFakeClick: () => null,
        notFoundContent: 'Not Found'
    };

    state = {
        width: 100,
        top: '-9999999px',
        left: '-9999999px'
    };

    componentDidMount() {
        this.setUpScroll();
        this.setUpPosition();
        window.addEventListener('resize', this.setUpPosition);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.setUpPosition);
    }
    componentDidUpdate() {
        this.setUpScroll();
    }

    setUpPosition = () => {
        const {parentId} = this.props;
        const parent = document.getElementById(parentId);

        if(parent){
            this.setState({
                width: parent.offsetWidth,
                top: offset(parent).top + parent.offsetHeight,
                left: offset(parent).left
            });
        }
    };

    setUpScroll = () => {
        if (this.scrollWrap && this.menu && this.content) {
            const hScrollWrap = this.scrollWrap.offsetHeight;
            const hMenu = this.menu.offsetHeight;
            const hContent = this.content.offsetHeight;

            const index = (hMenu - 16) / hContent;

            if (hMenu < hContent) {
                const minHeightScroll = 20;
                let hScroll = index * hScrollWrap;
                hScroll = hScroll > minHeightScroll ? hScroll : minHeightScroll;

                this.scroll.style.height = hScroll + 'px';
                this.scroll.style.opacity = 1;
                this.scroll.style.display = 'block';

                let timeout;

                this.menu.onscroll = e => {
                    const maxScroll = hScrollWrap - hScroll;
                    const iScrollTop = e.target.scrollTop * index;

                    this.scroll.style.opacity = 1;
                    this.scroll.style.top = (iScrollTop > maxScroll ? maxScroll : iScrollTop) + 'px';

                    if (timeout) {
                        clearTimeout(timeout);
                        timeout = null;
                    }

                    timeout = setTimeout(() => {
                        if (this.scroll) this.scroll.style.opacity = 0;
                    }, 500);
                };
            } else {
                this.scroll.style.display = 'none';
            }
        }

    };

    handleClick = e => {
        const el = e.target;
        const {onChange, onFakeClick} = this.props;

        onFakeClick();

        if (el.dataset.value) {
            onChange({text: el.innerText, ...el.dataset});
        }
    };

    parseOptGroup = data => compact(
        data.map(
            ({options, ...props}, index) => {
                return !isEmpty(options) && (
                    <OptGroup key={index} label={props.label} icon={props.icon}>
                        {[...this.parseOptions(options, {...props})]}
                    </OptGroup>
                )
            }
        )
    );

    parseOptions = (data, options) => {
        const {selected} = this.props;

        return compact(
            data.map(
                ({text, value, ...props}) => text
                    && value && (
                        <Option
                            key={value}
                            value={value}
                            {...props}
                            {...options}
                            isSelected={value === selected}
                        >
                            {text}
                        </Option>
                    )
            )
        );
    }

    render() {
        const {width, left, top} = this.state;
        const {options, visible, notFoundContent, loading} = this.props;
        const parseOptions = options[0] && options[0].options
            ? [...this.parseOptGroup(options)]
            : [...this.parseOptions(options)];

        return (
            <Portal>
                <div
                    ref={n => {
                        this.dropdown = n;
                    }}
                    className={cn('Dropdown', !visible && 'close')}
                    onClick={this.handleClick}
                    style={{width, left, top}}
                >
                    <div
                        ref={n => {
                            this.menu = n
                        }}
                        className="Dropdown-content-wrap"
                    >
                        <div
                            ref={n => {
                                this.content = n
                            }}
                            className="Dropdown-content"
                        >
                            {loading ? (
                                <Spinner color="rose"/>
                            ) : isEmpty(parseOptions) ? (
                                <div className="Dropdown-error">{notFoundContent}</div>
                            ) : (
                                parseOptions
                            )}
                        </div>
                    </div>

                    <div
                        ref={n => {
                            this.scrollWrap = n;
                        }}
                        className="scrollbar-wrap"
                    >
                        <div
                            ref={n => {
                                this.scroll = n;
                            }}
                            className="scrollbar"
                        />
                    </div>
                </div>
            </Portal>
        );
    }
}

export default Dropdown;
