export const groupTermsByType = (data) => (
    data.reduce((acc, value) => {
        if (!acc[value.type]) {
            acc[value.type] = [];
        }
        acc[value.type] = [...acc[value.type], {
            id: value.id,
            label: value.label,
            slug: value.slug
        }];
        return acc;
    }, {})
);
