import React from 'react';
import ReactDOM from 'react-dom';
import {useMountElement} from "~/hook/MountElement.hook";

function Portal({children}) {
    const mountEl = useMountElement();

    return mountEl && ReactDOM.createPortal(children, mountEl);
}

export default Portal;
