import {PROBLEM_TYPE, SERVICE_TYPE, STATION_TYPE, SYNONYM_TYPE, MASTER_TYPE} from "$/constants/servicesTypes";

export const SEARCH_GROUP_TYPES = {
    [SERVICE_TYPE]: {
        name: 'service',
        label: 'services: plural',
        classNameOption: 'option-service',
        classNameTag: 'tag-service',
    },
    [PROBLEM_TYPE]: {
        name: 'problems',
        label: 'service_synonym: problems',
        classNameOption: 'option-problems',
        classNameTag: 'tag-problems',
    },
    [STATION_TYPE]: {
        name: 'station',
        label: 'station: plural',
        classNameOption: 'option-station',
        classNameTag: 'tag-station',
    },
    [SYNONYM_TYPE]: {
        name: 'synonym',
        label: 'services: symptoms',
        classNameOption: 'option-synonym',
        classNameTag: 'tag-synonym',
    },
    [MASTER_TYPE]: {
        name: 'master',
        label: 'enums: user: role: serviceman',
        classNameOption: 'option-master',
        classNameTag: 'tag-master'
    }
};
