import React from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router'
import Form from 'antd/lib/form';
import {useDispatch, useSelector} from 'react-redux';
import {changeUrl, updateParams} from '~/helpers/url/utils';
import {getServicesByType, getSlug} from '$/helpers/parsers/searchOptions';
import {parseDate} from '$/helpers/parsers/moment';
import {handleSearchUrlChanging} from '$/helpers/url/search';
import {getCityFromAddress} from '$/helpers/url/parsers';
import {
    BRAND,
    CITY,
    DATE,
    DISTANCE,
    GEO_TYPE,
    LATITUDE,
    LONGITUDE,
    NORTH_EAST_LAT,
    NORTH_EAST_LNG,
    PROBLEM,
    SEARCH_BY_MAP,
    SERVICE,
    SOUTH_WEST_LAT,
    SOUTH_WEST_LNG,
    TIME
} from '$/constants/searchParameters';
import {SEARCH_PAGE} from '~/constants/urlTemplates';
import {PROBLEM_TYPE, SERVICE_TYPE, SYNONYM_TYPE} from '$/constants/servicesTypes';
import {LOCALITY} from '$/constants/geotypes';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import InputBrand from '~/components/fields/InputBrand/InputBrand';
import InputCalendar from '~/components/fields/InputCalendar/InputCalendar';
import {InputSearchPlace} from '~/components/fields/InputSearchPlace/InputSearchPlace';
import InputTime from '~/components/fields/InputTime/InputTime';
import Button from '~/components/buttons/Button/Button';
import {getLocationByIp, getLocationSlug, saveFields} from '$/redux/searchFields/actions';
import {setParsedUrlParams} from '$/redux/search/actions';

import {GOOGLE_MAP_URL} from '$/constants/constants'
import './FormSearch.scss';
import {SearchMultipleSelect} from '~/components/SearchMultipleSelect/SearchMultipleSelect';
import {useLocale} from '~/hook/Locale.hook';
import {useTranslations} from '~/hook/Translations.hook';

const {string, bool} = PropTypes;
const {Item} = Form;

FormSearch.propTypes = {
    withoutReload: bool,
    isDisabled: bool,
    placeholder: string.isRequired,
};

FormSearch.defaultProps = {
    withoutReload: false,
    isDisabled: false
};

function FormSearch(props) {
    const {
        placeholder,
        isDisabled,
        withoutReload = false
    } = props;

    const {selectedFields, searchDistance, activeFilters, parsedUrlParams} = useSelector(state => ({
        selectedFields: state.searchFields.selected,
        searchDistance: state.countryInfo.info.search_distance,
        activeFilters: state.searchFilters.active,
        parsedUrlParams: state.search.parsedUrlParams
    }))
    const dispatch = useDispatch();
    const {main, brand, place, date, time} = selectedFields;
    const t = useTranslations();
    const {locatesUrl, language} = useLocale();

    const handleClick = async () => {
        try {

            const preparePlaceData = async () => {
                if (!isEmpty(place)) {
                    const {lat = NaN, lng = NaN, type: placeType, types: placeTypes, address_components = []} = place;
                    const type = placeType || placeTypes;
                    const cityName = getCityFromAddress(!isEmpty(type) ? type : LOCALITY, address_components);
                    const citySlug = !isEmpty(cityName) ? await dispatch(getLocationSlug(cityName)) : undefined;

                    return {
                        lat,
                        lng,
                        type,
                        citySlug
                    };
                }
                const res = await dispatch(getLocationByIp());
                return {
                    lat: res?.coordinates?.lat ? res.coordinates.lat : NaN,
                    lng: res?.coordinates?.lng ? res.coordinates.lng : NaN,
                    type: res?.location?.slug ? 'locality' : undefined,
                    citySlug: res?.location?.slug
                };

            };
            const {lat, lng, type, citySlug} = await preparePlaceData();
            const {radius} = activeFilters;
            const [services, problems, synonyms] = [SERVICE_TYPE, PROBLEM_TYPE, SYNONYM_TYPE]
            .map((type) => getServicesByType(main, type));
            const {separator, template} = SEARCH_PAGE;
            const params = [
                {
                    prefix: SERVICE,
                    value: services
                },
                {
                    prefix: BRAND,
                    value: getSlug(brand)
                },
                {
                    prefix: CITY,
                    value: citySlug
                },
                {
                    prefix: DATE,
                    value: parseDate(date)
                },
                {
                    prefix: TIME,
                    value: time
                },
                {
                    prefix: PROBLEM,
                    value: synonyms ? `${problems}${problems ? '_' : ''}${synonyms}` : problems
                },
                {
                    prefix: LATITUDE,
                    value: lat
                },
                {
                    prefix: LONGITUDE,
                    value: lng
                },
                {
                    prefix: GEO_TYPE,
                    value: type
                },
                {
                    prefix: SOUTH_WEST_LAT,
                    value: ''
                },
                {
                    prefix: SOUTH_WEST_LNG,
                    value: ''
                },
                {
                    prefix: NORTH_EAST_LAT,
                    value: ''
                },
                {
                    prefix: NORTH_EAST_LNG,
                    value: ''
                },
                {
                    prefix: SEARCH_BY_MAP,
                    value: ''
                }
            ];

            if (type === LOCALITY || (isEmpty(type) && !isNaN(lat) && !isNaN(lng))) {
                if (parsedUrlParams.findIndex(value => value.prefix === DISTANCE) === -1) {
                    params.push({
                        prefix: DISTANCE,
                        value: radius || searchDistance
                    });
                }
            } else {
                params.push({
                    prefix: DISTANCE,
                    value: null
                });
            }

            await dispatch(saveFields());
            dispatch(setParsedUrlParams(params, [SEARCH_BY_MAP, NORTH_EAST_LNG, NORTH_EAST_LAT, SOUTH_WEST_LNG, SOUTH_WEST_LAT]));
            await changeUrl({
                separator,
                template,
                withoutReload,
                locale: locatesUrl,
                params: updateParams(parsedUrlParams, params),
                handler: (template) => handleSearchUrlChanging(template, main)
            });
        } catch(e) {
            console.log('Error: ', e);
        }
    };

    const isButtonDisabled = isEmpty(main)
        && isEmpty(brand)
        && isEmpty(place.text);

    const isSearchPage = Router.router.route === '/search';

    return (
        <Form className="form-search">
            <Row type="flex">
                <Col xs={24} md={16} xl={isSearchPage ? 7 : 16}>
                    <Item className="form-search__item">
                        <SearchMultipleSelect placeholder={placeholder}/>
                    </Item>
                </Col>
                <Col xs={24} md={8} xl={isSearchPage ? 4 : 8}>
                    <Item className="form-search__item">
                        <InputBrand withDisabled={isDisabled}/>
                    </Item>
                </Col>
                <Col xs={24} md={8} xl={isSearchPage ? 4 : 8}>
                    <Item className="form-search__item">
                        <InputSearchPlace
                            googleMapURL={GOOGLE_MAP_URL + `&language=${language}`}
                            withDisabled={isDisabled}
                        />
                    </Item>
                </Col>
                <Col xs={24} md={date ? 4 : 8} xl={isSearchPage ? (date ? 3 : 5) : 8}>
                    <Item className="form-search__item">
                        <InputCalendar withDisabled={isDisabled}/>
                    </Item>
                </Col>
                {(isSearchPage && date) && (
                    <Col xs={24} md={4} xl={2}>
                        <Item className="form-search__item">
                            <InputTime withDisabled={isDisabled}/>
                        </Item>
                    </Col>
                )}
                <Col xs={24} md={8} xl={isSearchPage ? 4 : 8}>
                    <Item className="form-search__item">
                        <Button
                            type="primary"
                            isUppercase
                            isFluid
                            onClick={handleClick}
                            disabled={isButtonDisabled}
                        >
                            {t('home: promo: buttons: find')}
                        </Button>
                    </Item>
                </Col>
            </Row>
        </Form>
    );
}

export default FormSearch;
