import isNull from 'lodash/isNull';
import {PROBLEM_TYPE, SERVICE_TYPE, SYNONYM_TYPE} from '../../constants/servicesTypes';
import {getServicesByType} from '../parsers/searchOptions';
import {PROBLEM, SERVICE} from '../../constants/searchParameters';

export const handleSearchUrlChanging = (template, allServices) => {
    const [services, problems, synonyms] = [SERVICE_TYPE, PROBLEM_TYPE, SYNONYM_TYPE]
        .map((type) => getServicesByType(allServices, type));

    if (!synonyms && problems.split('_').length === 1) {
        const serviceIndex = template.findIndex(item => item === SERVICE);

        return services
            ? template.map((item, index) => {

                if (index <= serviceIndex) {
                    return template[index];
                }

                if (index === serviceIndex + 1) {
                    return PROBLEM;
                }

                return template[index - 1];
            })
            : template.map(item => {

                switch (item) {
                    case SERVICE:
                        return PROBLEM;
                    case PROBLEM:
                        return null;
                    default:
                        return item;
                }
            })
                .filter(item => !isNull(item));
    }
    return template;
};
