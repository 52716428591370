import React, {memo} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './Option.scss';

const {node, string, bool} = PropTypes;

const Option = ({children, isSelected, value, color, icon, type, id, slug, brand_model_id}) => (
    <div
        className={cn('Dropdown__Option', isSelected && 'active')}
        data-value={value}
        data-color={color}
        data-icon={icon}
        data-type={type}
        data-id={id}
        data-slug={slug}
        data-brand_model_id={brand_model_id}
    >
        {children}
    </div>
);

Option.propTypes = {
    // props
    children: node,
    value: string,
    isSelected: bool,
    dataClass: string,
    dataIcon: string
};

export default memo(Option);
