import {parseServicesToString} from '../url/strings';
import _get from 'lodash/get';

export const getSlug = (item) => _get(item, 'slug', '');

export const filterServices = (services, _type) => services
    .filter(({type}) => parseInt(type) === parseInt(_type));

export const getServicesByType = (services, type) => parseServicesToString(filterServices(services, type).map(getSlug));

export const parseNetworkValue = (val) => {
  switch (val) {
    case 1:
      return 'true';
    case 2:
      return 'false';
    case 3:
      return 'default';
    default:
      return '';
  }
};
