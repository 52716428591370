import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setPlace } from '$/redux/searchFields/actions';
import { setBrowserPosition } from '$/redux/search/actions';
import get from 'lodash/get';
import { getPlace } from '$/helpers/getPlace';
import { useCookies, withCookies } from 'react-cookie';

const withPosition = (Page, options = { withPlaceName: false }) =>
  ({ setPlace, setBrowserPosition, language }) => {
    const [cookies, setCookie, removeCookie] = useCookies(['lat', 'lng']);

    const searchPosition = (success, error = () => {}) => {
      navigator.geolocation.getCurrentPosition(success, error, {
        maximumAge: Infinity
      });
    };

    const updatePlace = async (position) => {
      const { withPlaceName } = options;

      if (withPlaceName) {
        const place = await getPlace({
          ...position,
          language
        });
        setPlace(place);
      }
    };

    useEffect(() => {
      const lat = parseFloat(get(cookies, 'lat'));
      const lng = parseFloat(get(cookies, 'lng'));

      if (lat && lng) {
        searchPosition(async () => {
          setBrowserPosition({
            lat,
            lng
          });
          await updatePlace({ lat, lng });
        }, () => {
          removeCookie('lat');
          removeCookie('lng');
          setPlace({});
        });
      } else {
        searchPosition(async ({ coords }) => {
          const { latitude: lat, longitude: lng } = coords;
          setCookie('lat', lat);
          setCookie('lng', lng);
          setBrowserPosition({ lat, lng });
          await updatePlace({ lat, lng });
        }, () => {
          setPlace({});
        });
      }
    }, []);

    return <Page/>;
  };

const mapStateToProps = state => ({
  language: state.locate.language
});

const mapDispatchToProps = {
  setPlace,
  setBrowserPosition
};

export default compose(
  withCookies,
  connect(mapStateToProps, mapDispatchToProps),
  withPosition
);
