import './InputCalendar.scss';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import DatePicker from 'antd/lib/date-picker';
import {setDate} from '$/redux/searchFields/actions';
import {useTranslations} from '~/hook/Translations.hook';

const InputCalendar = () => {

    const t = useTranslations();
    const {date} = useSelector(state => ({
        date: state.searchFields.selected.date
    }));
    const dispatch = useDispatch();
    const disabledDate = current => current && current.endOf('day') < moment()
        .endOf('day');
    const onChange = newDate => dispatch(setDate(newDate));

    return (
        <div className="input-calendar">
            <DatePicker
                format="LL"
                value={typeof date === 'string' ? moment(date) : date}
                disabledDate={disabledDate}
                allowClear
                onChange={onChange}
                showToday={false}
                placeholder={t('home: promo: fields: datetime')}
                dropdownClassName="input-calendar__dropdown"
            />
        </div>
    );
};

export default InputCalendar;
