import './SearchMultipleSelect.scss';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getTermsRequest, setMain} from '$/redux/searchFields/actions';
import {Select, Spin} from 'antd';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import {SEARCH_GROUP_TYPES} from './SearchMultipleSelectConfig';
import {classes} from '~/helpers/classes';
import {groupTermsByType} from './helper';
import {useTranslations} from '~/hook/Translations.hook';
import {useLocale} from '~/hook/Locale.hook';
import {STATION_TYPE, MASTER_TYPE, stationUrlFormat} from '$/constants/servicesTypes';

const {Option, OptGroup} = Select;

SearchMultipleSelect.propTypes = {
    placeholder: PropTypes.string,
    tabId: PropTypes.number,
    maxTagCount: PropTypes.number,
    maxTagTextLength: PropTypes.number
};

SearchMultipleSelect.defaultProps = {
    placeholder: '',
    tabId: 1,
    maxTagCount: 10,
    maxTagTextLength: 100
};

// TODO tabId have to remove from server
export function SearchMultipleSelect(
    {
        placeholder,
        tabId,
        maxTagCount,
        maxTagTextLength,
    }) {

    const t = useTranslations();
    const dispatch = useDispatch();
    const {locatesUrl, language} = useLocale();
    const selectedTerms = useSelector(state => state.searchFields.selected.main);

    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [fetchTermsData, setFetchTermsData] = React.useState([]);
    const [groupTermsData, setGroupTermsData] = React.useState([]);

    React.useEffect(() => {
        const formatData = selectedTerms.map(item => {
            return {
                key: item.slug,
                label: item.text,
                value: item.text,
            }
        });
        setSelectedValues(formatData);
    }, [selectedTerms]);

    React.useEffect(() => {
        loadSearchData();
    }, []);

    React.useEffect(() => {
        setGroupTermsData(groupTermsByType(fetchTermsData));
    }, [fetchTermsData]);

    const loadSearchData = async (value = '') => {
        setIsLoading(true);
        setGroupTermsData([]);
        const {data} = await getTermsRequest(tabId, language, value);
        setFetchTermsData(data);
        setIsLoading(false);
    };

    const onSearch = debounce((value) => (
        loadSearchData(value)
    ), 500);

    const handleChange = value => {
        let redirectUrl = '';
        const selectedData = value.map(({key}) => {
            const findItem = fetchTermsData.find(item => `${item.id}-${item.slug}` === key);
            if (findItem.type === STATION_TYPE) {
                redirectUrl = stationUrlFormat({
                    locale: locatesUrl,
                    type: findItem.meta.marker.slug,
                    city: findItem.meta.city.slug,
                    slug: findItem.slug,
                });
            }
            if (findItem.type === MASTER_TYPE) {
                const params = {
                    slot_id: findItem.id
                };

                const paramsLink = new URLSearchParams(params).toString();

                redirectUrl = stationUrlFormat({
                    locale: locatesUrl,
                    type: findItem.meta.marker.slug,
                    city: findItem.meta.city.slug,
                    slug: findItem.slug,
                    paramsLink
                });
            }
            return {
                text: findItem.label,
                value: `${findItem.id}--${findItem.label}`,
                type: findItem.type,
                id: findItem.id,
                slug: findItem.slug,
            }
        });
        if (redirectUrl) {
            window.open(redirectUrl, '_blank');
        } else {
            dispatch(setMain(selectedData));
        }
    };

    return (
        <div className="search-multiple-select">
            <Select
                mode="multiple"
                className="search-multiple-select__select"
                dropdownClassName="search-multiple-select__dropdown"
                placeholder={placeholder}
                maxTagCount={maxTagCount}
                maxTagTextLength={maxTagTextLength}
                value={selectedValues}
                loading={isLoading}
                labelInValue
                filterOption={false}
                notFoundContent={isLoading ? <Spin size="small"/> : <div>{t('search_page: select: not_found')}</div>}
                onFocus={loadSearchData}
                onSearch={onSearch}
                onChange={handleChange}
            >
                {groupTermsData && Object.keys(groupTermsData).map((type) => (
                    <OptGroup
                        key={type}
                        label={t(SEARCH_GROUP_TYPES[type].label)}
                    >
                        {groupTermsData[type].map((item) => (
                            <Option
                                className={classes(
                                    'search-multiple-select__option',
                                    SEARCH_GROUP_TYPES[type].classNameOption,
                                )}
                                title={SEARCH_GROUP_TYPES[type].classNameTag}
                                key={`${item.id}-${item.slug}`}
                                id={item.id}
                            >
                                {item.label}
                            </Option>
                        ))}
                    </OptGroup>
                ))}
            </Select>
        </div>
    );
}
