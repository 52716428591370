import React, {memo} from 'react';
import PropTypes from 'prop-types';

import './OptGroup.scss';
import Icon from '../../Icon/Icon';

const {} = PropTypes;

OptGroup.propTypes = {};

function OptGroup(props) {
    const {children, label, icon} = props;

    return (
        <div className="Dropdown__OptGroup">
            <div className="label">
                {icon && <Icon type={icon}/>}
                {label}
            </div>
            {children}
        </div>
    );
}

export default memo(OptGroup);
