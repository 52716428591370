import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Input from 'antd/lib/input';
import isEmpty from 'lodash/isEmpty';
import Dropdown from '../Dropdown/Dropdown';
import Icon from '../Icon/Icon';

import './SelectWithInput.scss';

const {func, bool, array, string} = PropTypes;

let blur;

class SelectWithInput extends Component {
    static propTypes = {
        icon: string,
        placeholder: string,
        options: array,
        loading: bool,
        onFocus: func,
        onBlur: func,
        onSearch: func,
        onSelect: func
    };

    static defaultProps = {
        options: [],
        onFocus: () => null,
        onBlur: () => null,
        onSearch: () => null,
        onSelect: () => null
    };

    constructor(props) {
        super(props);
        this.state = {
            id: Date.now(),
            data: props.initialData || {},
            value: '',
            options: [],
            visibleDropdown: false,
            fakeFocus: false
        };
    }

    componentDidUpdate(prevProps) {
        const {options, loading} = this.props;

        if (prevProps.options !== options || prevProps.loading !== loading) {
            this.setState({
                options,
                visibleDropdown: !isEmpty(options) || loading
            });
        }
    }

    handleFocus = () => {
        const {onFocus} = this.props;
        const {fakeFocus} = this.state;

        if (blur) clearTimeout(blur);
        if (!fakeFocus) onFocus();
        this.setState({fakeFocus: false});
    };

    handleSelect = data => {
        const {onSelect} = this.props;

        this.setState({data, value: '', visibleDropdown: false});
        onSelect(data);
    };


    focusInput = () => this.Input && this.Input.input.focus();

    handleChange = e => {
        const {value} = e.target;
        const {onSearch} = this.props;

        this.setState({data: {}, value});
        onSearch(value);
    };

    handleBlur = () => {
        blur = setTimeout(() => {
            const {onBlur} = this.props;

            this.setState({
                value: '',
                visibleDropdown: false
            });
            onBlur();
        }, 200);
    };

    render() {
        const {id, data, options, visibleDropdown} = this.state;
        const {icon, placeholder, loading, value} = this.props;

        return (
            <div className="SelectWithInput" id={id}>
                <Input
                    ref={n => {
                        this.Input = n;
                    }}
                    value={value}
                    prefix={icon && <Icon type={icon}/>}
                    placeholder={placeholder}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                />

                <Dropdown
                    key={id}
                    parentId={id}
                    selected={data.value}
                    visible={visibleDropdown}
                    options={options}
                    onChange={this.handleSelect}
                    loading={loading}
                    // onFakeClick={this.handleFakeClick}
                />
            </div>
        );
    }
}

export default SelectWithInput;
