import * as React from "react";
import {useIsMounted} from "~/hook/IsMounted.hook";

export const useMountElement = () => {
    const isMounted = useIsMounted();
    const mountEl = React.useMemo(() => (
        (isMounted) ? document.createElement("div") : null
    ), [isMounted]);

    React.useEffect(() => {
        if (mountEl) {
            document.documentElement.appendChild(mountEl);
        }
        return () => mountEl && document.documentElement.removeChild(mountEl);
    }, [mountEl]);

    return mountEl;
};